import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useUser from 'auth/hooks/useUser'
import LoadingFallback from 'common/components/LoadingFallback'

/**
 * FIXME: store redirection
 * Normally this should be triggered by 'redirects()' in next.config.js.
 * But this solution even with regex could cover all endpoints like /favicon.icon etc.
 * If a better solution is found, replace it immediately.
 */
export default function StoreLoginRedirect() {
  const router = useRouter()
  const { storeSlug } = router.query as { storeSlug: string }
  const { user } = useUser()

  useEffect(() => {
    // prefetch pages
    router.prefetch(`/${storeSlug}/auth/login`)
    router.prefetch(`/`)

    // wait for user authentication
    if (!user) return

    // user is logged in, redirect to the store main page
    if (user.auth.authenticated) {
      router.replace({
        pathname: '/',
        query: {
          storeSlug: storeSlug,
        },
      })
      return
    }

    // redirect to login pages
    router.replace(`/${storeSlug}/auth/login`)
  }, [router, storeSlug, user])

  return <LoadingFallback />
}
